.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*VK style*/
.contact_main_bg {
  background-image: url(../src/assets/images/contact_main_bg.jpg);
  background-size: 100% 100%;
}

.tabs_one ul li {
  display: inline-block;
}

.tabs_one ul li a {
  display: block;
  padding: 5px 15px;
  border-bottom: 1px solid #C8CBD0;
  color: #667085;
  
}

.tabs_one ul li a.active {
  border: 1px solid #101828;
  border-bottom: 0;
  border-radius: 5px 5px 0px 0px;
  font-weight: 500;
  color: #101828;
}

.tabs_two ul li {
  display: inline-block;
}

.tabs_two ul li a {
  display: block;
  padding: 5px 15px;
  border-bottom: 2px solid #B3DDC7;
  color: #667085;
}

.tabs_two ul li a.active {
  border-bottom-color: #029046;
  font-weight: 500;
  color: #101828;
}

.box_btns ul li {
  display: inline-block;
}

.box_btns ul li a {
  display: block;
  border: 1px solid #C6CBD2;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(16, 24,
      40, 0.05);
  padding: 0.417vw 0.521vw;
  font-size: 0.729vw;
}

.box_btns ul li a.active {
  border-color: #029046;
}

.box_btns ul li a.disable {
  border-color: #E4E7EC;
}

.popup_header img {
  max-width: 44.271vw;
}

.popup_display_img img {
  max-width: 32.448vw;
}

.successfully_box {
  border: 1px solid #E4E7EC;
  border-radius: 10px;
}

.successfully_box .bdr:not(:last-child) {
  border-right: 1px solid #E4E7EC;
}


.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0,
      0.6);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  transition: opacity 1s;
}

.dashbord_box_shape {
  background: no-repeat;
  background-position: bottom right;
  background-color: #fff !important;
}
.dark .dashbord_box_shape {
  background: no-repeat;
  background-position: bottom right;
  background-color: #2A2E32 !important;
}

.boxShadow {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}

.boxH {
  height: 100%;
  min-height: 14.667vw;
}



.tag_i img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #fff;
}

.tag_i li:not(:first-child) {
  margin-left: -10px;
}

.dashbord_box_img2 img {
  max-width: 32.448vw;
}

.tableYer tr td {
  padding: 10px;
  border-bottom: 1px solid #E4E7EC;
}

.planning_period {
  position: relative;
}

.planning_period:after {

  content: "";
  position: absolute;
  bottom: 38px;
  left: 0px;
  right: 0;
  background-color: #B3DDC7;
  width: 87%;
  height: 4px;
  margin: 0 auto;
  border-radius: 3px;
  z-index: -1;
}

@media only screen and (max-width:991px) {
  .popup_header img {
    max-width: 100%;
  }

  .popup_display_img img {
    max-width: 100%;
  }

  .box_btns ul li a {
    padding: 5px;
    font-size: 12px;
  }

  .popup_header img {
    min-height: 150px;
    object-fit: cover;
  }

  .boxH {
    min-height: 250px;
  }
}

/*VK style*/


/*Right Side Popup Animation style*/
@keyframes popupDiv-slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes popupDiv-slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

/*Right Side Popup Animation style*/


/* Organizational Chart css */
.organizationchart-demo .card {
  overflow-x: auto;
}

.organizationchart-demo .p-organizationchart .p-person {
  padding: 0;
}

.org-tree-container{
padding: 0px !important; 
} 

.org-tree-node-btn{
  top: 95% !important; 
}
.dark .org-tree-node-label-inner{
  background-color: #4A5056 !important;
}
.dark .org-tree-container {
background: transparent !important;
}


.tabactive .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link 
{
  color: #029046 !important;
  border: 2px solid transparent !important;
box-shadow: none !important;
background-color: none !important;
background-color: #F0F3F8;
}

.tabactivebottom .p-tabview .p-tabview-nav {
  background: #F0F3F8 ;
}

 .activetab .p-tabview .p-tabview-nav  .p-tabview-nav-link{
  /* background:red ; */
  background: #F0F3F8 ;

}




/* .tabactivebottom 
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: none !important;
} */



 .tabactive .icon {
	position: relative;
	width: 24px;
	height: 10px;
}

 .tabactive .icon:before {
	position: absolute;
	top: 10;
	left: 0;
	content: "";
	display: inline-block;
	width: 50px;
	height: 50px;
	background-repeat: no-repeat;
}

 .tabactive .keyinsighticon:before {
  background-image: url('./assets/svg/KeyInsighticon.svg');
  height: 50px;
	/* background-image: url(./assets/svg/paper_icon.svg); */
}
.tabactive .noticeicon:before {
  background-image: url('./assets/svg/noticeicon.svg');
  height: 50px;
	/* background-image: url(./assets/svg/paper_icon.svg); */
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar{
  background-color: green !important;
}

.list-metric ul li {
  background: #F0F3F8;
  border: 2px solid #F0F3F8;
  margin: 4px;
  padding: 8px;
  display: flex;
  color: black;
  cursor: pointer;
}
.lis
.dark .list-metric ul li {
  background: #2a2e32;
  color: #5E656D;
}

.list-metric  ul  .active {
  background: #019049;
  /* border-bottom: 4px solid #377B3B; */
  color: rgb(255, 244, 244);
}

.list-metric input[type="radio"] {
  background-color: #019049;
}