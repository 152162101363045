@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600&family=Roboto:ital,wght@0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: 'Inter', sans-serif !important;
	background-color: #F9FAFB !important;
	line-height: 1.5;
}

body {
	background-color: #F9FAFB;
	letter-spacing: -0.02em;
	background-image: url(./assets/images/bg-body.png);
	background-size: cover;
	height: 100%;
	min-height: 100vh;
}

/****************** scrollbar*************************/
::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
	background-color: #C4C4C4;
	border-radius: 5px;

}

::-webkit-scrollbar-track {
	background-color: rgba(229, 231, 235, var(--bg-opacity));
	cursor: pointer;
	background: #f2f2f2;

}

::-webkit-scrollbar-thumb {
	cursor: pointer;
	background-color: #B3DDC7;
	/*outline: 1px solid slategrey;*/
	border-radius: 5px;
}
.dark ::-webkit-scrollbar-thumb {
	cursor: pointer;
	background-color: #333;
	/*outline: 1px solid slategrey;*/
	border-radius: 5px;
}
.dark ::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
	background-color: #667085;
	border-radius: 5px;

}
.dark ::-webkit-scrollbar-track {
	background-color: #667085;
	cursor: pointer;
	

}



/****************** scrollbar end*************************/
/************************** Login page***********************/

.login-bg {
	background-color: #EEF8F4;
	height: 100%;
}

.shadowgooglebtn {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.login form .icon {
	position: relative;
	width: 24px;
	height: 24px;
}

.login form .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
}

.login form .eye-slash:before {
	background-image: url(./assets/svg/eye-slash.svg);
	left: -33px;
	
}
.eyes_style 
{
	top: 43px;
    position: absolute;
    right: 16px;
	color: #667085;
	font-size: 18px;
}

/************************** Login page end***********************/
/************************** landing page***********************/
.landing .left-side-bg {
	background-image: url(./assets/images/left-side-landing.png);
	background-size: cover;
	background-position: top center;

}

.landing .quotes {
	font-family: 'Roboto Slab', serif;
	position: relative;
	padding: 17px 56px;
	margin-top: 100px;
	line-height: 37px;
}

.landing .quotes:before {
	position: absolute;
	top: -45px;
	left: 0;
	content: "";
	display: inline-block;
	background-repeat: no-repeat;
	background-image: url(./assets/images/quotes-before.png);
	width: 450px;
	height: 70px;
}

.landing .quotes:after {
	position: absolute;
	bottom: -50px;
	left: 0;
	content: "";
	display: inline-block;
	background-repeat: no-repeat;
	background-image: url(./assets/images/quotes-after.png);
	width: 450px;
	height: 70px;
}

.landing .feature .box {
	background-image: url(./assets/images/grid-bg.png);
	background-size: 100% 100%;

}

.landing .landing-hr-right {
	background-image: url(./assets/images/landing_right_hr.png);
	background-size: cover;
	background-position: top center;
}

.landing .hrrightside-bg {
	background: rgba(33, 16, 36, 0.6);
	border: 1px solid #A88986;
	border-radius: 10px;
}

.landing .hr-ftf-bg {
	background-image: url(./assets/images/hr-ftf-bg.png);
	background-size: cover;
	background-position: top center;
}

.landing-hr .hr-ftf-bg .icon {
	position: relative;
	width: 40px;
	height: 42px;
}

.landing-hr .hr-ftf-bg .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 50px;
	height: 50px;
	background-repeat: no-repeat;
}

.landing-hr .hr-ftf-bg .total_emp_icon:before {
	background-image: url(./assets/images/total-emp-icon.png);
	width: 40px;
	height: 42px;

}

.landing-hr .hr-ftf-bg .role_icon:before {
	background-image: url(./assets/images/role_icon.png);
	width: 40px;
	height: 42px;

}

.landing-hr .hr-ftf-bg .sales_icon:before {
	background-image: url(./assets/images/sales_icon.png);
	width: 40px;
	height: 42px;

}

.landing-hr .hr-ftf-bg .brand_icon:before {
	background-image: url(./assets/images/brand_icon.png);
	width: 40px;
	height: 42px;

}

/************************** landing page end***********************/
/************************************left*************************/

.leftbar {
	width: 30px;
}

.left-wrapper nav .menu .icon {
	position: relative;
	width: 35px;
	height: 35px;
	position: relative;
	display: table-cell;
	width: 60px;
	height: 36px;
	text-align: center;
	vertical-align: middle;
	font-size: 20px;
}

.hoverclass:hover {
	background-color: #039855 !important;
	border-radius: 5px;
	width: 35px;
	height: 35px;
}

.left-wrapper nav .menu .icon:before {
	position: absolute;
	top: 0;
	left: 2px;
	content: "";
	display: inline-block;
	width: 35px;
	height: 25px;
	background-repeat: no-repeat;
}

.left-wrapper nav .menu .home_icon:before {
	background-image: url(./assets/svg/home_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .home_icon:before {
	background-image: url(./assets/svg/home_active_icon.svg);
	top: 7px;
}

.left-wrapper nav .active .home_icon:before {
	background-image: url(./assets/svg/home_active_icon.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .dashboard_icon:before {
	background-image: url(./assets/svg/dashboard_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .dashboard_icon:before {
	background-image: url(./assets/svg/dashboard_icon_active.svg);
	top: 7px;
}

.left-wrapper nav .active .dashboard_icon:before {
	background-image: url(./assets/svg/dashboard_icon_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .parameter_icon:before {
	background-image: url(./assets/svg/parameter_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .parameter_icon:before {
	background-image: url(./assets/svg/parameter_icon_active.svg);
	top: 7px;
}
.left-wrapper  nav .active .parameter_icon:before {
	background-image: url(./assets/svg/parameter_icon_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .planing_icon:before {
	background-image: url(./assets/svg/planing_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .planing_icon:before {
	background-image: url(./assets/svg/planing_icon_active.svg);
	top: 7px;
}
.left-wrapper  nav .active  .planing_icon:before {
	background-image: url(./assets/svg/planing_icon_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .task_square:before {
	background-image: url(./assets/svg/task-square.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .task_square:before {
	background-image: url(./assets/svg/task_square_active.svg);
	top: 7px;
}
.left-wrapper nav .active .task_square:before {
	background-image: url(./assets/svg/task_square_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .change_request_icon:before {
	background-image: url(./assets/svg/change_request_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .change_request_icon:before {
	background-image: url(./assets/svg/change_request_icon_active.svg);
	top: 7px;
}
.left-wrapper nav .active .change_request_icon:before {
	background-image: url(./assets/svg/change_request_icon_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .comment_icon:before {
	background-image: url(./assets/svg/comment_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .comment_icon:before {
	background-image: url(./assets/svg/comment_icon_active.svg);
	top: 8px;
}
.left-wrapper  nav .active  .comment_icon:before {
	background-image: url(./assets/svg/comment_icon_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .support_icon:before {
	background-image: url(./assets/svg/support_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu .reports_icon:before {
	background-image: url(./assets/svg/reports.svg);
	top: 8px;
}
.left-wrapper nav .menu:hover .reports_icon:before {
	background-image: url(./assets/svg/reports_white.svg);
	top: 8px;
}
.left-wrapper  nav .active .reports_icon:before {
	background-image: url(./assets/svg/reports_white.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu:hover .support_icon:before {
	background-image: url(./assets/svg/support_icon_active.svg);
	top: 8px;
}
.left-wrapper   nav .active  .support_icon:before {
	background-image: url(./assets/svg/support_icon_active.svg);
	top: 9px;
	left: 9px;
}

.left-wrapper nav .menu .logout_icon:before {
	background-image: url(./assets/svg/logout_icon.svg);
	top: 8px;
}

.left-wrapper nav .menu:hover .logout_icon:before {
	background-image: url(./assets/svg/logout_icon_active.svg);
	top: 8px;
}

.left-wrapper nav .menu .small_logo:before {
	background-image: url(./assets/svg/small-logo.svg);
	width: 35px;
	height: 47px;
	padding: 9px 13px;
	left: -7px;

}

.left-wrapper ul li {
	height: 42px;
	position: relative;

}


.group:hover .group-hover\:block {
	display: block !important;
}

.group ul li {
	height: auto !important;
}

.left-wrapper .small_icon {
	position: relative;
	width: 15px;
	height: 15px;
}

.left-wrapper .small_icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
}

.left-wrapper .sub_file:before {
	background-image: url(./assets/svg/sub_file.svg);
}

.left-wrapper .sub_user_switch_icon:before {
	background-image: url(./assets/svg/sub_user_switch_icon.svg);
}

.left-wrapper .sub_user_icon:before {
	background-image: url(./assets/svg/sub_user.svg);
}

.left-wrapper .calender_icon:before {
	background-image: url(./assets/svg/calendar_icon.svg);
}

.left-wrapper .notification_icon:before {
	background-image: url(./assets/svg/bell_notification.svg);
}



.main-menu:hover,
nav.main-menu.expanded {
	width: 220px;
	overflow: visible;
}

.main-menu {
	background: #fff;
	position: absolute;
	top: 0px;
	bottom: 0;
	height: 100%;
	left: 0px;
	width: 64px;
	overflow: hidden;
	transition: width .8s;
	transition: width .08s;
	-webkit-transform: translateZ(0) scale(1, 1);
	z-index: 1000;
}

.main-menu>ul {
	margin: 12px 20px;
}

.main-menu li {
	position: relative;
	display: block;
	width: 195px;
	text-align: left;
}

.main-menu li>button {
	position: relative;
	display: table;
	border-collapse: collapse;
	border-spacing: 0;
	color: #101828;
	font-weight: 500;
	font-size: 14px;
	text-decoration: none;
	transition: all 0.3s;
}

.main-menu .nav-icon {
	position: relative;
	display: table-cell;
	width: 80px;
	height: 36px;
	text-align: center;
	vertical-align: middle;
	font-size: 18px;
}

.main-menu .nav-text {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	width: 160px;
	padding: 10px 20px 10px 0;
	text-align: left;
}

.main-menu>ul.logout {
	position: absolute;
	left: 1px;
	bottom: 70px;
}

.no-touch .scrollable.hover {
	overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
	overflow-y: auto;
	overflow: visible;
}

button:hover,
button:focus {
	text-decoration: none;
}

nav {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

nav ul,
nav li {
	outline: 0;
	margin: 0;
	padding: 0;
}

.main-menu li:hover>button,
nav.main-menu li.active>button,
.dropdown-menu>li>button:hover,
.dropdown-menu>li>button:focus,
.dropdown-menu>.active>button,
.dropdown-menu>.active>button:hover,
.dropdown-menu>.active>button:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover button,
.dashboard-page nav.dashboard-menu ul li.active button {
	color: #fff;
	box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
	background: #029046;
	border-radius: 6px;
	padding: 5px 12px;
	position: relative;
	left: -5px;
}


.area {
	float: left;
	width: 100%;
	height: 100%;
}

.left-wrapper .main-menu ul .active
{
	background-color:#029046 ;
	position: relative;
    left: -8px;
	border-radius: 8px;
	width: 43px;
}
.left-wrapper .main-menu ul .active:hover 
{
	width: 190px;
	background-color: transparent;
	
}
.main-menu ul .active .nav-text
{
	padding-left: 28px;
    right: -26px;
	
}
.main-menu ul .active .nav-text:hover
{
    right: -15px
}
.main-menu .active .nav-text
{
	width: 160px;
	min-width: 160px;
} 





/************************************left end*************************/

.letter_spacing {
	letter-spacing: -0.02em;
}


.headar-wrapper {}

.headar-wrapper nav .icon {
	position: relative;
	width: 40px;
	height: 40px;
}

.headar-wrapper nav .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
}

.headar-wrapper nav .notification_icon:before {
	background-image: url(./assets/svg/notification_icon.svg);
	width: 40px;
	height: 40px;
}

.headar-wrapper nav .moon_icon:before {
	background-image: url(./assets/svg/moon_icon.svg);
}

.headar-wrapper nav .sun_icon:before {
	background-image: url(./assets/svg/sun_icon.svg);
	top: 4px;
}


.headar-wrapper .icon-size {
	position: relative;
	width: 16px;
	height: 16px;
}

.headar-wrapper .icon-size:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
}


.user_icon:before {
	background-image: url(./assets/svg/header_user.svg);
}

.lock_icon:before {
	background-image: url(./assets/svg/lock_icon.svg);
}

.logout_icon_small:before {
	background-image: url(./assets/svg/logout_icon_small.svg);
	width: 22px;
	height: 22px;
}
.parameter_icon_small:before {
	background-image: url(./assets/svg/parameter_icon_small.svg);
	width: 22px;
	height: 22px;
}
.import_icon:before {
	background-image: url(./assets/svg/import_icon.svg);
	width: 20px !important;
    height: 20px !important;
}
.logout_icon:before {
	background-image: url(./assets/svg/logout_icon.svg);
}
.box_btns .icon {
	position: relative;
	width: 15px;
	height: 15px;
	margin-right: 19px;
}

.box_btns .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
}
.box_btns .eye_small_icon:before {
	background-image: url(./assets/svg/eye_small_icon.svg);
}

.box_btns .check_circle_green_icon_small:before {
	background-image: url(../src/assets/svg/check_circle_green_icon_small.svg);
	top: 0px !important;
}

.box_btns .edit_small_icon:before {
	background-image: url(./assets/svg/edit_small_icon.svg);
}
.box_btns .check_diseble:before {
	background-image: url(./assets/svg/check_diseble_icon.svg);
}
.page-title {
	color: #101828;
	font-weight: 600;
	font-size: 22px;
}
/*************************** Dashboard*****************************/
.dashboard-main-wrapper .top-action-wrapper .text-gray-600 {
	color: #61648F !important;
}

.dashboard-main-wrapper .top-action-wrapper .border-gray-600 {
	border-color: #61648F;
}

.dashboard-main-wrapper .top-action-wrapper .icon {
	position: relative;
	width: 16px;
	height: 16px;
}

.dashboard-main-wrapper .top-action-wrapper .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
}

.dashboard-main-wrapper .icon {
	position: relative;
	width: 24px;
	height: 24px;
}

.dashboard-main-wrapper .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
}

.dashboard-main-wrapper .paper_icon:before {
	background-image: url(./assets/svg/paper_icon.svg);
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-transparent {
	--bg-opacity: 1;
	color: #61648F !important;
	background-color: transparent !important;
	border-color: rgba(97, 100, 143, var(--bg-opacity));
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-transparent:hover {
	--bg-opacity: 1;
	color: #fff !important;
	background-color: #1570EF !important;
	border-color: rgba(97, 100, 143, var(--bg-opacity));
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-yellow {
	background-color: #FCF4CA;
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-yellow h4 {
	/*font-size: 18px;*/
	line-height: 24px;
	color: #D1B000;
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-pink {
	background-color: #FCCAEE;
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-pink h4 {
	/*font-size: 18px;*/
	line-height: 24px;
	color: #D10071;
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-orange {
	background-color: #FCDCCA;
}

.dashboard-main-wrapper .contract-grid-wrapper .contract-grid-block .bg-orange h4 {
	/*font-size: 18px;*/
	line-height: 24px;
	color: #D13200;
}

.modal-hide {
	display: none;
}

.badge-pending {
	color: #DC6803;
	font-weight: 500;
	font-size: 12px;
	background: #FEF0C7;
	border-radius: 16px;
	padding: 5px 12px;
}

.badge-rejected {
	color: #B42318;
	font-weight: 500;
	font-size: 12px;
	background: #FEF3F2;
	border-radius: 16px;
	padding: 5px 12px;
}

.badge-approved {
	color: #039855;
	font-weight: 500;
	font-size: 12px;
	background: #D1FADF;
	border-radius: 16px;
	padding: 5px 12px;
}



.dashboard-main-wrapper  .search_icon:before {
	position: absolute;
	content: "";
	background-image: url(./assets/svg/search_icon_light.svg);
	background-repeat: no-repeat;
	height: 24px !important;
	width: 20px !important;
	top: -9px !important;
	right: 2px;
}

.bg-body-blue {
	background: url(./assets/images/bg-body.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #4775FF !important;
	height: 100%;
}

/*************************** Dashboard*****************************/
.profile-pic {
	width: 200px;
	max-height: 200px;
	display: inline-block;
}

.edit_icon:before {
	background-image: url(../src/assets/svg/Edit_Square.svg);
	top: -10px !important;
}
.userFile_icon:before {
	background-image: url(../src/assets/images/userFile.png);
	top: -10px !important;
}
.add_icon:before {
	background-image: url(../src/assets/svg/Edit_Square.svg);
	top: -10px !important;
}

.delete_icon:before {
	background-image: url(../src/assets/svg/delete_icon.svg);
	top: -10px !important;
}

.p-datatable-wrapper .delete_icon:before {
	background-image: url(../src/assets/svg/delete_icon.svg);
	left: -7px !important;
}

.change_password:before {
	background-image: url(../src/assets/svg/key_icon.svg);
	top: -10px !important;
}

.remove_row_icon:before {
	background-image: url(../src/assets/svg/delete_row_icon.svg);
	
}



.ajs-button {
	border: solid 1px #ccc;
	font-size: 14px;
}

.ajs-ok {
	border: solid 1px #ccc;
	background-color: #016939;
	color: #FFF;
}

.ajs-header {
	background-color: #f2f2f2 !important;
	font-weight: bold;
	font-size: 18px;
}

.ajs-body {
	font-size: 15px;
}

.ajs-content {
	padding-left: 0px !important;
}

.ajs-cancel {
	border: solid 1px #ccc;
	background-color: #FFF;
	color: #000;
}

.fc-h-event {
	border: none !important;
	background-color: transparent !important;
}

.file-upload {
	display: none;
}

.circle {
	border-radius: 100% !important;
	/* overflow: hidden; */
	width: 128px;
	height: 128px;
	position: relative;
	top: 0px;
	border: 1px solid #029046;
}

.p-image {
	position: absolute;
	top: 90%;
	right: 35%;
	color: #666666;
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	background-color: #029046;
	padding: 4px 7px;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	cursor: pointer;
}

.p-image:hover {
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.upload-button {
	font-size: 1.2em;
}

.upload-button:hover {
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	color: #999;
}

.upload_icon {
	display: inline-block;
	position: relative;
	height: 18px;
	width: 20px;
}

.upload_icon:before {
	position: absolute;
	content: "";
	background-image: url(./assets/svg/camera.svg);
	background-repeat: no-repeat;
	height: 24px !important;
	width: 20px !important;
	top: 3px;
	right: 0px;
}

.reminder-box {
	background: #fff;

	border-radius: 10px;
	padding: 10px;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.reminder-wrapper .active {
	background: #2D5BE5;
}

.reminder-wrapper h4 {
	color: #8C8C8C;
	font-weight: 400;
	font-size: 12px;

}

.reminder-wrapper p {
	font-weight: 300;
	font-size: 15px;
	line-height: 23px;
	letter-spacing: -0.02em;
	margin-bottom: 10px;
	color: #344054
}

.reminder-wrapper .active h4 {
	color: #C1D1FF;
	font-weight: 400;
	font-size: 12px;
}

.reminder-wrapper .active p {
	font-weight: 300;
	font-size: 15px;
	line-height: 23px;
	letter-spacing: -0.02em;
	margin-bottom: 10px;
	color: #fff;
}

.reminder-wrapper p {
	font-weight: 300;
	font-size: 15px;
	line-height: 23px;
	letter-spacing: -0.02em;
	margin-bottom: 10px;
	color: #344054
}

.dashboard-main-wrapper .reminder-box .paper_icon:before {
	background-image: url(./assets/svg/paper_icon_grey.svg);
}

.left-wrapper .settings_icon:before {
	background-image: url(../src/assets/svg/settings.svg);
}
.left-wrapper .customize_icon:before {
	background-image: url(../src/assets/svg/custmizeForm.svg);
}
.left-wrapper .fielTrip_icon:before {
	background-image: url(../src/assets/svg/fieldtripIcon.svg);
}
.left-wrapper nav .menu .interpeter_app_icon:before {
	background-image: url(../src/assets/appicons/interpeter.png);
}
.bg-btn-blue {
	background: #029046;
}

/*VK style*/
.contact_main_bg {
	background-image: url(../src/assets/images/contact_main_bg.jpg);
	background-size: 100% 100%;
}

.tabs_one ul li {
	display: inline-block;
}

.tabs_one ul li a {
	display: block;
	padding: 5px 15px;
	border-bottom: 1px solid #C8CBD0;
}

.tabs_one ul li a.active {
	border: 1px solid #101828;
	border-bottom: 0;
	border-radius: 5px 5px 0px 0px;
	font-weight: 500;
}

.tabs_two ul li {
	display: inline-block;
}

.tabs_two ul li a {
	display: block;
	padding: 5px 15px;
	border-bottom: 2px solid #B3DDC7;
}

.tabs_two ul li a.active {
	border-bottom-color: #029046;
	font-weight: 500;
}

.box_btns ul li {
	display: inline-block;
}

.box_btns ul li a {
	display: block;
	border: 1px solid #C6CBD2;
	border-radius: 10px;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	padding: 0.417vw 0.521vw;
	font-size: 0.729vw;
}

.box_btns ul li a.active {
	border-color: #029046;
}

.box_btns ul li a.disable {
	border-color: #E4E7EC;
}

.popup_header img {
	max-width: 44.271vw;
}

.popup_display_img img {
	max-width: 32.448vw;
}

.successfully_box {
	border: 1px solid #E4E7EC;
	border-radius: 10px;
}

.successfully_box .bdr:not(:last-child) {
	border-right: 1px solid #E4E7EC;
}

.sidenav {
	height: 100%;
	/* 100% Full-height */
	width: 0;
	/* 0 width - change this with JavaScript */
	position: fixed;
	/* Stay in place */
	z-index: 999;
	/* Stay on top */
	top: 0;
	right: 0;
	background-color: #fff;
	/* Black*/
	overflow-x: hidden;
	/* Disable horizontal scroll */
	transition: 0.5s;
	/* 0.5 second transition effect to slide in the sidenav */
	box-shadow: 0px 20px 65px rgba(2, 10, 18, 0.6);
}



/* .dashbord_box_shape {
	background: url(../src/assets/images/dashboard_box_planning_bg.png) no-repeat  !important;
	background-position: bottom right !important;
	background-size: cover;
} */
.dashbord_box_shape {
	background: url(../src/assets/images/box_design.svg) no-repeat  !important;
	background-position: bottom right !important;
	background-size: cover;
}
.dark .dashbord_box_shape {
	background: url(../src/assets/images/dashboard_box_planning_bg_dark.png) no-repeat  !important;
	background-position: bottom right !important;
	background-size: cover;
}
.target-main-wrapper .dashbord_box_shape {
	background: url(../src/assets/images/box_design_orange.svg) no-repeat  !important;
	background-position: bottom right !important;
	background-size: cover;
	background-color: #fff !important;
}
.dark .target-main-wrapper .dashbord_box_shape {
	background: url(../src/assets/images/box_design_orange.svg) no-repeat  !important;
	background-position: bottom right !important;
	background-size: cover;
	background-color: #2A2E32 !important;
}
.dark .target-main-wrapper .dashbord_box_shape {
	background: url(../src/assets/images/dashboard_box_planning_bg_dark.png) no-repeat  !important;
	background-position: bottom right !important;
	background-size: cover;
}

  .dark .target-main-wrapper  .dashbord_box_shape {
	background: no-repeat;
	background-position: bottom right !important;
	background-color: #2A2E32 !important;
  }
  .dashbord_box_shape_review_green
  {
	content: "";
	background-size: cover;
	width: 155px;
	height: 165px;
	position: absolute;
	background: url(../src/assets/images/review_commit_img.png) no-repeat  !important;
	top: 0;
    left: 0
  }
  .dashbord_box_shape_review_green_regular
  {
	content: "";
	background-size: cover;
	width: 155px;
	height: 165px;
	position: absolute;
	background: url(../src/assets/images/review_commit_img_regular.png) no-repeat  !important;
	top: -1px;
    left: 0
  }
  .annual_target_img_green
  {
	content: "";
	background-size: cover;
	width: 230px;
	height: 170px;
	position: absolute;
	background: url(../src/assets/images/annual_target_img_green.png) no-repeat  !important;
	top: 11px;
    right: 0px;
  }
  .annual_target_img_green_regular
  {
	content: "";
	background-size: cover;
	width: 230px;
	height: 170px;
	position: absolute;
	background: url(../src/assets/images/annual_target_img_regular.png) no-repeat  !important;
	top: 8px;
    right: 2px;
  }
  .annual_target_box 
  {
	height: 173px;
  }

  

.boxShadow {
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}
.h-38
{
	height: 150px;
}

.boxH {
	height: 100%;
	min-height: 16.667vw;
}

.dashbord_box_img1 img {
	max-width: 10.885vw;
}

.tag_i img {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	border: 1px solid #fff;
}

.tag_i li:not(:first-child) {
	margin-left: -10px;
}
.height-custtom
{
	height: 580px;
	overflow: auto;
}


.target-main-wrapper .icon {
	position: relative;
	width: 15px;
	height: 15px;

}

.target-main-wrapper .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 22px;
    height: 22px;
	background-repeat: no-repeat;
}
.target-main-wrapper .arrow_left_double:before {
	background-image: url(./assets/svg/arrow_left_double.svg);
	width: 20px;
	height: 20px;
	margin-right: 24px
}
.target-main-wrapper .t_icon:before {
	background-image: url(./assets/svg/t_icon.svg);
	width: 40px;
	height: 40px;
	left: -27px;
}
.activegreen
{
	background-color: #4FB155;
}
.target-main-wrapper .s_icon:before {
	background-image: url(./assets/svg/s_icon.svg);
	width: 40px;
	height: 40px;
	left: -22px;
}
.target-main-wrapper .activegreen .t_icon:before
{
	background-image: url(./assets/svg/t_white_icon.svg);
	width: 40px;
	height: 40px;
	left: -27px;
}
.target-main-wrapper .activegreen .s_icon:before {
	background-image: url(./assets/svg/s_white_icon.svg);
	width: 40px;
	height: 40px;
	left: -22px;
}

.org-tree-container {
	background-color: transparent !important;
}

.org-tree-node:after
{
	
    border-left: 1px solid #545454 !important;
}
.org-tree-node:not(:first-child):before, .org-tree-node:not(:last-child):after
{
	/* border-left: 1px solid #333 !important; */
}
.org-tree-node:not(:first-child):before, .org-tree-node:not(:last-child):after
{
	/* border-left: 1px solid #333 !important; */
}
.org-tree-node:not(:first-child):before, .org-tree-node:not(:last-child):after {
    border-top: 1px solid #333 !important;
}

.org-tree-node-label .org-tree-node-label-inner 
{
	padding: 0 !important;
	box-shadow: none !important;
}
/* .org-tree-node:before:nth-child()
{
	content: none !important;
} */


.target-main-wrapper .btnbtn {
	position: relative;
	width: 20px;
	height: 20px;
	top: -2px;

}

.target-main-wrapper .btnbtn:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
}

.target-main-wrapper .import_icon:before {
	background-image: url(./assets/svg/import_icon.svg);
	width: 20px !important;
    height: 20px !important;
}


.annual_target_bg
{
	background-image: url(./assets/images/financialyearimg.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 120px;
}


@media only screen and (max-width:991px) {
	.popup_header img {
		max-width: 100%;
	}

	.popup_display_img img {
		max-width: 100%;
	}

	.box_btns ul li a {
		padding: 5px;
		font-size: 12px;
	}

	.popup_header img {
		min-height: 150px;
		object-fit: cover;
	}
		

}
@media only screen and (max-width:1400px)
{
	.height-custtom
	{
		height: 500px;
		overflow: auto;
	}
}
@media only screen and (max-width:1600px)
{
	.height-custtom
	{
		height: 550px;
		overflow: auto;
	}
}

@media only screen and (max-width:1366px)
{
	.height-custtom
	{
		height: 480px;
		overflow: auto;
	}
}
.planning-main-wrapper .box_btns ul li a {
	font-size: 13px;
	border-color: #C6CBD2;
	padding: 5px 8px;
    border-radius: 7px;
}

.dashboard-main-wrapper .box_btns ul li a {
	font-size: 13px;
	border-color: #C6CBD2;
	padding: 5px 8px;
	border-radius: 7px;
}
.dashboard-main-wrapper .box_btns ul li .outlined{
	border: 1px solid #029046;
	color: #029046;
}

.evalution-main-wrapper .box_btns ul li a {
	font-size: 13px;
	border-color: #C6CBD2;
	padding: 5px 8px;
	border-radius: 7px;
}


.p-datatable .p-datatable-thead > tr > th
{
	font-size: 15px;
	font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr > td
{
	font-size: 13px;
}

.rdt_TableRow
{
	min-height: 52px;
}

/*VK style*/

.react-datepicker__input-container input {
	margin-top: 5px;
	padding: 9px
}

.css-1pahdxg-control,
.css-1s2u09g-control,
.css-1s2u09g-control:focus {
	border: 1px solid #d0d5dd !important;
	border-radius: 9px !important;
	height: 42.5px;
	padding: 1px !important;
	font-size: 15px !important;
}

.p-calendar .p-button {
	color: #ffffff;
	background: #2f9047;
	border: 1px solid #2f9047;
}

.p-calendar {
	width: 100%;
	height: 40px;
}
input:focus {
	border: 1px solid #2f9047;
  }
  select:focus {
	border: 1px solid #2f9047;
  }
  .bg-btn-blue
  {
	--tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	--tw-bg-opacity: 1;
    background-color: rgb(2 144 70 / var(--tw-bg-opacity));
  }
  .setting-wrapper .shadowisetting
  {
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
  }

  .reportshadow
  {
	border: 1px solid #EEF8F4;
	box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
  }


/***************** Chat Css******************/

.chat-wrapper .tabs_chat ul li {
	display: inline-block;
	font-size: 13px;
}

.chat-wrapper .tabs_chat ul li a {
	display: block;
	padding: 5px 15px;
	border-bottom: 2px solid #B3DDC7;
	color: #667085;
}

.chat-wrapper .tabs_chat ul li a.active {
	border-bottom-color: #029046;
	font-weight: 600;
	color: #101828;
}
.chat-wrapper .shadow-chatbox
{
	box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}
.chat-wrapper .chatname
{
	color: #344054;
	font-weight: 600;
	font-size: 14px;
}
.chat-wrapper .time
{
	color: #98A2B3;
	font-weight: 500;
	font-size: 12px;
}
.chat-wrapper .chat-list  a .active 
{
	background: #E5F3EC;
	box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}
.chat-wrapper .chat-list  a .shadow-chatbox:hover 
{
	background: #f0fcf6;
	box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
	
}
.chat-wrapper .chat-list ul .active .chatname
{
	color: #101828;
}
.chat-wrapper .chat-list ul .active .time
{
	color: #667085;
}
.chat-wrapper .chat-list a:hover
{
	background: #E5F3EC;
	box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}
.chat-wrapper .h-110
{
	height: 28rem;
}
.chat-wrapper .chat-header
{
	background: #F9FAFB;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
	border-radius: 10px;
	padding: 10px 14px;
}
.chat-wrapper .chat-header h5
{
	color: #101828;
	font-weight: 700;
	font-size: 16px;
}
.chat-wrapper .chat-header h6
{
	color: #667085;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;	
}

.chat-wrapper .chat-header .oicon {
	display: inline-block;
	position: relative;
	height: 18px;
	width: 20px;
}

.chat-wrapper .chat-header .option_icon:before {
	position: absolute;
	content: "";
	background-image: url(./assets/svg/chat-option-icon.svg);
	background-repeat: no-repeat;
	height: 38px !important;
	width: 38px !important;
	top: 3px;
	right: 0px;
}
.chat-wrapper .chat-body .receive-message
{
	background-color: #EEF8F4;
	border-radius: 8px;
	color: #000000;
	font-weight: 400;
	font-size: 14px;
}
.chat-wrapper .chat-body .send-message
{
	background-color: #1B9B58;
	border-radius: 8px;
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}

.chat-wrapper .chat-body .checkmarkicon {
	display: inline-block;
	position: relative;
	height: 18px;
	width: 20px;
}

.chat-wrapper .chat-body .readmark:before {
	position: absolute;
	content: "";
	background-image: url(./assets/svg/read-checkmark_icon.svg);
	background-repeat: no-repeat;
	height: 15px !important;
	width: 15px !important;
	bottom: 0px;
    right: 0px;
}
.chat-wrapper .chat-body .readmark_white:before {
	position: absolute;
	content: "";
	background-image: url(./assets/svg/read-checkmark_icon_white.svg);
	background-repeat: no-repeat;
	height: 15px !important;
	width: 15px !important;
	bottom: 0px;
    right: 0px;
}
.chat-wrapper .chat-body .time
{
	font-weight: 500;
	font-size: 11px;
}
.chat-wrapper .chat-body ul li 
{
	margin-bottom: 20px !important;
}
.chat-wrapper .msg-send-section .icon
{
	display: inline-block;
	position: relative;
	height: 24px;
	width: 24px;
}
.chat-wrapper .msg-send-section .smile:before
{
	position: absolute;
	content: "";
	background-image: url(./assets/svg/smiley_icon.svg);
	background-repeat: no-repeat;
	height: 30px !important;
    width: 30px !important;
}
.chat-wrapper .msg-send-section .attach_icon:before
{
	position: absolute;
	content: "";
	background-image: url(./assets/svg/attach_icon.svg);
	background-repeat: no-repeat;
	height: 30px !important;
    width: 30px !important;
}
.chat-wrapper .msg-send-section .media .icon
{
	display: inline-block;
	position: relative;
	height: 40px;
	width: 40px;
}
.chat-wrapper .msg-send-section .chat_video_icon:before
{
	position: absolute;
	content: "";
	background-image: url(./assets/svg/chat_video_icon.svg);
	background-repeat: no-repeat;
	height: 40px !important;
    width: 40px !important;
}
.chat-wrapper .msg-send-section .chat_mic_icon:before
{
	position: absolute;
	content: "";
	background-image: url(./assets/svg/chat_mic_icon.svg);
	background-repeat: no-repeat;
	height: 40px !important;
    width: 40px !important;
}

.chat-wrapper .msg-send-section input 
{
	background: #FFFFFF;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
	border-radius: 60px;
}

 /******** custom chatbox************/
 .react-emoji 
 {
	 display: contents !important;
 }

 .react-emoji-picker--wrapper 
 {
	left: 10px;
    height: 357px;
    width: 354px !important;
 }



/***************** Chat Css End******************/

/****************** notification ******************/
.notification{

	position: relative;
	display: inline-block;
  }
  .notification .notBtn{
	transition: 0.9s;
	cursor: pointer
  }
  .notification .box{
	width: 400px;
	height: 0px;
	border-radius: 10px;
	transition: 0.5s;
	position: absolute;
	overflow-y: scroll;
	padding: 0px;
	left: -130px;
	margin-top: 53px;
	background: #FFFFFF;
	border: 1px solid #E4E7EC;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
	border-radius: 8px;
	cursor: pointer;
  }
  .notification .notBtn:hover > .box{
	height: 35vh
  }
  .notification .display{
	position: relative;
  }
  .notification .cont:empty{
	display: none;
  }
  .notification .stick{
	text-align: center;  
	display: block;
	font-size: 50pt;
	padding-top: 70px;
	padding-left: 80px
  }
  
  .notification .notification-block
  {
  background: #F9FAFB;
  border-bottom: 1.5px solid #B3DDC7;
  padding: 15px 15px;
  }
  .notification .display .icon
  {
	  display: inline-block;
	  position: relative;
	  height: 24px !important;
	  width:24px !important;
  }
  .notification .display .warn_icon:before
  {
	  position: absolute;
	  content: "";
	  background-image: url(./assets/svg/warn_icon.svg);
	  background-repeat: no-repeat;
	  height: 24px !important;
	  width: 24px !important;
  }
  .notification .display .close_icon_notification:before
  {
	  position: absolute;
	  content: "";
	  background-image: url(./assets/svg/close_icon_notification.svg);
	  background-repeat: no-repeat;
	  height: 24px !important;
	  width: 24px !important;
  }
  /****************** notification ******************/

  /************ ToolTip ************************/
  .tooltippostion
  {
	top: -45px;
    right: 8px;
  }
  /************ ToolTip end ************************/
  .myself .imgprofile
  {
	width: 55px;
    height: 55px;
  }
  .chevron-right:before
  {
	position: absolute;
	  content: "";
	  background-image: url(./assets/svg/chevron-right.svg);
	  background-repeat: no-repeat;
	  height: 24px !important;
	  width: 24px !important;
  }
  .chevron-left:before
  {
	position: absolute;
	  content: "";
	  background-image: url(./assets/svg/chevron-left.svg);
	  background-repeat: no-repeat;
	  height: 24px !important;
	  width: 24px !important;
  }

  /************ Dashboard new******************************/
  .dashboard-box-shadow
  {
	box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
	border-radius: 6px;
  }

  .dashboard-new  .tabs_two ul li a.active {
    border-bottom-color: #029046;
    font-weight: 600;
    border-bottom: 3px solid #029046;
}
  /************ Dashboard new end******************************/
  .circle-text 
  {
	  font-size: 18px;
	  color:#101828;
	  font-weight: 600;
  }
  .dark .circle-text 
  {
	  color:#fff;
  }



  /*************** silder **********************/

/* CarouselDemo.css */



.silder_managetarget .carousel-demo .product-item .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.silder_managetarget .carousel-demo .product-item .product-item-content
{
	border-color: transparent !important;
	margin: 0 !important;
	margin: 0 0.3rem !important;
	padding: 0.5rem 0 !important; 
}
.silder_managetarget .p-carousel .p-carousel-indicators
{
	display: none !important;
}
.silder_managetarget .p-carousel .p-carousel-content .p-carousel-prev, .silder_managetarget .p-carousel .p-carousel-content .p-carousel-next
{
	margin: 0  !important;
	background: #89c59f !important;
	
}
.silder_managetarget .p-carousel .p-carousel-content .p-carousel-next span
{
	font-size: 12px !important;
}
.silder_managetarget .p-carousel .p-carousel-content .p-carousel-prev span
{
	font-size: 12px !important;
}
.silder_managetarget .p-carousel-container
{
	position: relative !important;
}
.silder_managetarget .p-carousel .p-carousel-content .p-carousel-prev
{
	position: absolute;
	left: 0;
	z-index: 9;
}
.silder_managetarget .p-carousel .p-carousel-content .p-carousel-next
{
	position: absolute;
	right: 0;
	z-index: 9;
}
.silder_managetarget .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #dee2e6;
    background: #fff !important;
}

                 
  /*************** silder new **********************/
/*************** accordionsingle**********************/
.accordionsingle .p-accordion .p-accordion-header .p-accordion-header-link
{
	background: #FFFFFF !important;
	border: 1px solid #E4E7EC;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
	border-radius: 8px;
}
.accordionsingle .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon
{
	position: absolute;
    right: 25px;
    top: 24px;
}
.accordionsingle .p-accordion-header-text
{
	font-weight: 500;
	color:#101828;
}
.accordionsingle .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #4fb15621;
}
.accordionsingle .p-accordion .p-accordion-content
{	
	padding: 10px;
}
.accordionsingle .pi-chevron-right:before
{
	position: absolute;
	content: "" !important;
	background-image: url(./assets/svg/caret_arrow_up_light.svg);
	background-repeat: no-repeat;
	height: 8px !important;
	width: 14px !important;
}
.accordionsingle .pi-chevron-down:before
{
	position: absolute;
	content: "" !important;
	background-image: url(./assets/svg/caret_arrow_down_light.svg);
	background-repeat: no-repeat;
	height: 8px !important;
	width: 14px !important
}

.accordionsingle  .p-datatable .p-datatable-thead > tr > th
{
	padding: 12px;
    font-size: 12px;
}

.accordionsingle  .p-datatable-tbody
{
	height: 150px;
	overflow: auto;
}

.accordionsingle  .p-datatable .p-datatable-tbody > tr > td, .accordionsingle .p-datatable .p-datatable-tfoot > tr > td
{
	padding: 8px;
    font-size: 12px;
}

.dark .accordionsingle  .p-datatable .p-datatable-tfoot > tr > td
{
	background: #33363a !important;
	color: #fff;
	border:solid 1px #33363a;
}


input[type=checkbox] {
	accent-color: #029046;
  }


  .my-react-select-container .my-react-select__indicator-separator
  {
	display: none;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #2f904736 !important;
    border-color: #4fb155 !important;
  }
.p-calendar
{
	height: 42px;
}
.p-calendar .p-inputtext:enabled:hover
{
	border-color: #4fb155;
}

/**************** dark*********************/
.dark .dashboard-main-wrapper .search_icon:before {
	
	background-image: url(./assets/svg/search_icon_dark.svg);
}
.dark .accordionsingle .p-accordion .p-accordion-header .p-accordion-header-link
{
	background: #2a2e32 !important;
    border: 1px solid #202429;
	
}
.dark .accordionsingle .p-accordion-header-text
{
	color: #fff;
}
.dark .accordionsingle .p-accordion .p-accordion-content
{
	border: 1px solid #33373a;
    background: #2a2e32;
}
.dark .accordionsingle .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link
{
	border-color: #3e4042;
}
.dark .accordionsingle .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link
{
	border-color: #3e4042;
}

.dark .accordionsingle .pi-chevron-right:before
{
	
	background-image: url(./assets/svg/caret_arrow_up_dark.svg);
	
}
.dark .accordionsingle .pi-chevron-down:before
{

	background-image: url(./assets/svg/caret_arrow_down_dark.svg);

}
/**************** dark*********************/
/*************** accordionsingle**********************/
/*************** accordionmulti start **********************/

.accordionmulti .p-accordion .p-accordion-header .p-accordion-header-link
{
	background: #FFFFFF !important;
	border: 1px solid #E4E7EC;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
	border-radius: 8px;
}
.accordionmulti .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon
{
	position: absolute;
    right: 25px;
    top: 24px;
}
.accordionmulti .p-accordion-header-text
{
	font-weight: 500;
	color:#101828;
}
.accordionmulti .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #4fb15621;
}
.accordionmulti .p-accordion .p-accordion-content
{	
	padding: 10px;
}
.accordionmulti .pi-chevron-right:before
{
	position: absolute;
	content: "" !important;
	background-image: url(./assets/svg/caret_arrow_up_light.svg);
	background-repeat: no-repeat;
	height: 8px !important;
	width: 14px !important;
}
.accordionmulti .pi-chevron-down:before
{
	position: absolute;
	content: "" !important;
	background-image: url(./assets/svg/caret_arrow_down_light.svg);
	background-repeat: no-repeat;
	height: 8px !important;
	width: 14px !important
}
.accordionmulti .p-accordion .p-accordion-tab {
    margin-bottom: 15px;
}

/**************** dark*********************/
.dark .accordionmulti .p-accordion .p-accordion-header .p-accordion-header-link
{
	background: #2a2e32 !important;
    border: 1px solid #202429;
	
}
.dark .accordionmulti .p-accordion-header-text
{
	color: #fff;
}
.dark .accordionmulti .p-accordion .p-accordion-content
{
	border: 1px solid #33373a;
    background: #2a2e32;
}
.dark .accordionmulti .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link
{
	border-color: #3e4042;
}
.dark .accordionmulti .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link
{
	border-color: #3e4042;
}

.dark .accordionmulti .pi-chevron-right:before
{
	
	background-image: url(./assets/svg/caret_arrow_up_dark.svg);
	
}
.dark .accordionmulti .pi-chevron-down:before
{

	background-image: url(./assets/svg/caret_arrow_down_dark.svg);

}
/**************** dark*********************/
/*************** accordionmulti end **********************/
/************** new datatable*******************/
.rdt_Table 
{
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
	border-top-left-radius:10px;
	border-top-right-radius:10px;
}
.dark .rdt_Table 
{
    background: #333;
    border: 1px solid #3e3e3e;
	color: #FFF;
    box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
	border-top-left-radius:10px;
	border-top-right-radius:10px;
}
.p-datatable-table
{
	background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
    border-radius: 10px;
}

.dark .p-datatable .p-datatable-thead > tr > th
{
	color: #F9FAFB!important;
    background: #292c30!important;
    font-size: 14px;
    border-color: #292c30 !important;
    border: 1px solid #292c30;
}
.dark .rdt_TableHeadRow, .dark .rdt_TableHead, .dark .rdt_TableCol
{
	color: #F9FAFB!important;
    background: #292c30!important;
}
/************** new datatable****************/





/*************** partner-wrapper ******************/
.partner-wrapper
{
	font-family: 'Roboto', sans-serif;
}
.partner-wrapper .box
{
	background: #FFFFFF;
	border-radius: 7px;
	cursor: pointer;

 }/*
.partner-wrapper .box .img-section 
{
	-webkit-transition: 0.4s ease;
  	transition: 0.4s ease;
  	overflow: hidden;
}
.partner-wrapper .box .img-section img:hover
{
	-webkit-transform: scale(1.08);
	transform: scale(1.08);
	border-radius: 7px;
} */

.partner-wrapper .box:hover
{
	filter: drop-shadow(0px 8px 19px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.05));

}

.dark .partner-wrapper .box
{
	background: #2A2E32;
	color: #fff;
	cursor: pointer;
}
.partner-wrapper .box .img-top img 
{
	border-top-right-radius: 7px;
	border-top-left-radius: 7px;
	height: 160px;
    width: 100%;

}
.partner-wrapper .box .title-section
{
	background: #F9FAF9;
    opacity: 0.8;
    padding: 10px 15px;
    width: 100%;
	color: #09230A;
	font-weight: 500;
	font-size: 16px;
}
.partner-wrapper .box h6
{
	font-family: 'Roboto Slab', serif;
}
.partner-wrapper .arrowicon .icon 
{
	height: 13px;
	width: 8px;
}
.partner-wrapper .box .arrow_down_green:before {
	background-image: url(../src/assets/images/arrow_down_green.svg);
}
.partner-wrapper .status .box
{
	background: rgba(255, 255, 255, 0.12);
	border-radius: 4px;
	padding: 20px 25px;
}

.partner-wrapper .status .icon 
{
	height: 48px;
	width: 48px;
}
.partner-wrapper .status .businessunit_icon:before {
	background-image: url(../src/assets/svg/connectpro/businessunit_icon.svg);
	height: 48px;
	width: 48px;
}
.partner-wrapper .status .regions_icon:before {
	background-image: url(../src/assets/svg/connectpro/regions_icon.svg);
	height: 48px;
	width: 48px;
}
.partner-wrapper .status .brand_icon:before {
	background-image: url(../src/assets/svg/connectpro/brand_icon.svg);
	height: 48px;
	width: 48px;
}
.partner-wrapper .status .customers_icon:before {
	background-image: url(../src/assets/svg/connectpro/customers_icon.svg);
	height: 48px;
	width: 48px;
}

.NetSales .echarts-for-react 
{
	height: 80px !important;
}
.option1
{
	height: 280px;
}
.ReceivablesAgingOption
{
	height: 280px;
}
.partner-popup h2
{
	font-family: 'Roboto Slab', serif;	
	font-size: 28px;
}


.partner-popup .panel-header  .icon 
{
	height: 24px;
	width: 24px;
}
.partner-popup .panel-header .more_icon:before {
	background-image: url(../src/assets/svg/more_vertical_icon.svg);
}
.partner-wrapper .partner-popup .panel-content .arrowicon .icon 
{
	height: 13px;
	width: 18px;
}

.partner-wrapper .partner-popup .panel-content .arrow_down_green:before {
	background-image: url(../src/assets/svg/uparrowgreen.svg);
}
.partner-wrapper .partner-popup .panel-content .arrow_down_white:before {
	background-image: url(../src/assets/svg/uparrowwhite.svg);
}
.partner-wrapper .partner-popup .panel-content .table-custom table 
{
	font-size: 10px;
}
.partner-wrapper .partner-popup .panel-content .table-custom table th
{
	padding: 14px 8px;
	border: 1px solid #fff;
    color: #000;
    font-weight: 500;
	font-size: 13px;
}
.partner-wrapper .partner-popup .panel-content .table-custom table td
{
	
	padding: 5px 10px;
	color:  #45584F;
	font-weight: 400;
	font-size: 12px;
	border: 1px solid #ECECEC;
}
.partner-wrapper .my-react-select-container .my-react-select__input-container, .partner-wrapper .my-react-select-container .my-react-select__placeholder, .partner-wrapper .my-react-select-container .my-react-select__single-value
{
	font-size: 12px;
}
.partner-wrapper .my-react-select-container .my-react-select__control
{
	height: auto;
    border-radius: 5px;
}
.partner-wrapper .my-react-select-container .my-react-select__menu
{
  font-size: 12px;
}

/*************** partner-wrapper End ******************/
  

/* DarkMode Theme css */
.dark body {
	background-image: url(./assets/images/dark_bg-body.png);
	background-size: cover;
	height: 100%;

}

.dark .main-menu
{
	background-color: #232528;
}
.dark .tabs_two ul li a.active {
    border-bottom-color: #029046;
    font-weight: 600;
    color: #4FB155;
}
.dark .headar-wrapper nav .sun_icon:before {
	background-image: url(../src/assets/svg/dark_sun_icon.svg);
}
.dark .headar-wrapper nav .notification_icon:before {
	background-image: url(../src/assets/svg/dark_bell_notification.svg);
}
/************ left nav dark *************/
.dark .left-wrapper nav .menu .home_icon:before {
	background-image: url(./assets/svg/home_active_icon.svg);
}
.dark .left-wrapper nav .menu .dashboard_icon:before {
	background-image: url(./assets/svg/dashboard_icon_active.svg);
}
.dark .left-wrapper nav .menu .parameter_icon:before {
	background-image: url(./assets/svg/parameter_icon_active.svg);
}
.dark .left-wrapper nav .menu .planing_icon:before {
	background-image: url(./assets/svg/planing_icon_active.svg);
}
.dark .left-wrapper nav .menu .task_square:before {
	background-image: url(./assets/svg/task_square_active.svg);
	
}
.dark .left-wrapper nav .menu .change_request_icon:before {
	background-image: url(./assets/svg/change_request_icon_active.svg);
}
.dark .left-wrapper nav .menu .reports_icon:before {
	background-image: url(./assets/svg/reports_white.svg);
}

.dark .left-wrapper nav .menu .comment_icon:before {
	background-image: url(./assets/svg/comment_icon_active.svg);
}
.dark .left-wrapper nav .menu .support_icon:before {
	background-image: url(./assets/svg/support_icon_active.svg);
}
.dark .left-wrapper nav .menu .logout_icon:before {
	background-image: url(./assets/svg/logout_icon_active.svg);
	top: 8px;
}
.dark .main-menu li>button {
	color: #fff;
}
.dark .logout_icon_small:before {
	background-image: url(./assets/svg/logout_icon_small_white.svg);
}
.dark .parameter_icon_small:before {
	background-image: url(./assets/svg/parameter_icon_smalldark.svg);
}

.p-datatable-emptymessage td
{
	text-align: center!important;
}

.p-datatable .p-datatable-thead > tr > th
{
	color: #344054!important;
    background: #F9FAFB!important;
	font-size: 14px;
	padding: 0.5rem 1rem;
}




.p-datatable .p-sortable-column .p-sortable-column-icon
{
	color: #FFF!important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #4fb156 !important;
    background: #318437 !important;
	
}
.p-datatable .p-datatable-tbody > tr.p-highlight
{
	background: #E5F3EC !important;
    color: #525252 !important;
}

.dark .p-datatable .p-datatable-tbody > tr.p-highlight{
	background-color: transparent !important;
	color: #fff !important;
}



.dark .target-main-wrapper .arrow_left_double:before {
	background-image: url(./assets/svg/arrow_left_double_dark.svg);
}
.dark .p-datatable .p-datatable-thead > tr > th
{
	color: #FFF;
    background: #4fb155;
}

.dark .p-datatable .p-datatable-tbody > tr
{
	color: #d4d4d4;
    background: #33363a;
}

.dark .p-datatable .p-datatable-tbody > tr > td
{
	border: 1px solid #2A2E32;
}
.dark .p-datatable-wrapper .p-selectable-row:hover 
{
	background-color: #2A2E32 !important;
	color: #fff !important;
}

.dark .rdt_Table > div
{
	background-color: #4A5056;
    color: #FFF;
}


.dark .rdt_TableBody div:hover 
{
	border-bottom-color: transparent;
    outline-color:transparent;
}
.dark .p-datatable .p-datatable-tfoot > tr > td
{
	background: #181b1e;
	border: 1px solid #424242;
	color: #f8f9fa;
}


/************ left nav dark end *************/
.dark .sidenav 
{
	background-color: #1E2124;
}



.dark .tabs_two ul li a {
    border-bottom: 2px solid #667085;
    color: #667085;
}
.dark .p-inputtext
{
	background-color: #333231;
    border: 1px solid #333231 !important;
}
.dark .p-calendar-w-btn-right .p-datepicker-trigger:hover
{
	background-color: #071008 !important ;
	border: 1px solid #071008 !important;
}
.dark input, .dark textarea 
{
	background-color: #2A2E32;
	border: 1px solid #4E5668;
	color: #fff;
}

.dark label
{
	color:#fff;
}
.dark .bg-btn-blue
{
	border:1px solid #029046;
}
.dark select:focus
{
	border: 1px solid rgb(51 51 51) !important;
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 3 3 / var(--tw-ring-opacity));
    background-color: #232429;
	color: #fff;
}
.dark .css-6j8wv5-Input 
{
	color:#fff;
}
.dark input:focus {
	border: 1px solid rgb(51 51 51) !important;
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 3 3 / var(--tw-ring-opacity));
    background-color: #232429;
}




  .my-react-select-container .my-react-select__control {
	height: 42px;
	border-radius:8px;
    @apply bg-white dark:bg-[#333231] border-neutral-300 dark:border-[#333231] hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-neutral-100 dark:bg-neutral-700 border-neutral-300 dark:border-neutral-600;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }

  /******** small select2 *******************/
  .divSmallControls .my-react-select-container .my-react-select__control {
    height: 38px;
    border-radius: 4px;
	font-size: 12px;
  }
  .divSmallControls .my-react-select-container .my-react-select__control .my-react-select__placeholder
  {
	font-size: 12px;
  }



  .divSmallControls .my-react-select__input-container
  {
	height: 31px !important;
  }
  .divSmallControls input {
    height: 38px;
    border-radius: 4px;
	font-size: 12px;
  }
  .divSmallControls  .my-react-select-container .my-react-select__menu
  {
	font-size: 12px;
  }
  /******** small select2 end *******************/


   /******** small table select2 *******************/
   .divSmallTableControls .p-dropdown {
    height: 38px;
    border-radius: 4px;
  }

  .divSmallTableControls  .p-dropdown .p-dropdown-label.p-placeholder,   .divSmallTableControls  .p-dropdown .p-dropdown-label {
    padding: 8px;
	font-size: 12px;
  }

  .p-dropdown-items
  {
	font-size: 12px;
  }

  .divSmallTableControls input {
    height: 38px;
    border-radius: 4px;
	font-size: 12px;
	width: 100px;
  }

  .dark .divSmallTableControls  .p-datatable .p-datatable-tfoot > tr > td
	{
		background: #33363a !important;
		color: #fff;
		border:solid 1px #33363a;
	}

 
  /******** small table select2 end *******************/
/***************** tab View dashboard *****************/
.circulartab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link

{
	background: #019049;
	box-shadow: 0px 10px 15px rgba(2, 10, 18, 0.1);
	border-radius: 2px 2px 10px 10px;
	color: #fff;
	border-color: transparent !important;
}
.circulartab .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
{
	box-shadow: inset 0 0 0 0.2rem #c7d2fe00;
}
.circulartab .p-tabview .p-tabview-nav .p-tabview-ink-bar
{
	height: 0;
}
.circulartab .p-tabview-title
{
	font-weight: 500;
}
.circulartab .p-tabview .p-tabview-nav
{
	border: transparent !important;
	background: transparent;
	color: #171F19;
}
.circulartab .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
{
	background: transparent;
    border-color: #309049;
    color: #171F19;
   
}
.circulartab .p-tabview .p-tabview-nav li .p-tabview-nav-link 
{
	font-size: 14px;
	padding: 10px 16px;
	background-color: transparent;
	color: #171F19;
}
.circulartab .p-tabview-panels
{
	margin-top: 20px;
}

.circulartab .p-tabview .p-tabview-panels
{
	background-color: transparent;
	padding: 0;
}
.dark .circulartab .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
{
	color:#fff;
}

/*******dark mood tab view *******************/
.dark .circulartab .p-tabview .p-tabview-nav li .p-tabview-nav-link
{
	color: #ffffff;
}
/*******dark mood tab view end *******************/
/*************** tab menu **************************/
.dashboard-new .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link
{
	border-width: 0 0 1px 0;
    font-size: 15px;
    font-weight: 400;
    padding: 6px;
}
.dashboard-new .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link
{
	background: #ffffff;
    border-color: #101828;
    color: #101828;
}
.dashboard-new .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus
{
	box-shadow: inset 0 0 0 0.2rem #c7d2fe00;
}
.dashboard-new .teamrevenueboxshadow
{
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.dark .dashboard-new .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link
{
	background: #ffffff00;
    border-color: #fff;
    color: #fff;
}
.dark .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link
{
	background: #ffffff00;
}
.dark .p-tabmenu .p-tabmenu-nav
{
	background: #202429;
    border: 1px solid #181d1e00;


}

.chat-height
{
	height:250px;
	margin-bottom: 26px;
}

/********************* tab View dashboard *************/

.conflict:before
  {
	position: absolute;
	  content: "";
	  background-image: url(./assets/svg/conflict_icon.svg);
	  background-repeat: no-repeat;
	  height: 20px   !important;
		width: 20px !important;
  }
  .dark .conflict:before
  {
	
	  background-image: url(./assets/svg/conflict_icon_dark.svg);
	
  }

.messages_icon:before
  {
	position: absolute;
	  content: "";
	  background-image: url(./assets/svg/messages_icon.svg);
	  background-repeat: no-repeat;
	  height: 20px   !important;
		width: 20px !important;
  }


.dark .p-datepicker:not(.p-datepicker-inline)
{
	background: #0b0c0d;    
	color: #fff;
}
.dark .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header
{
	background: #141616;
    color: #fff;
    border-bottom: 1px solid #393939;
}
.dark .p-datepicker .p-datepicker-header .p-datepicker-prev, .dark .p-datepicker .p-datepicker-header .p-datepicker-next
{
	color:#fff;
}
.dark .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .dark .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month
{
	color:#fff;
}
.dark .box_btns .eye_small_icon:before {
	background-image: url(./assets/svg/eye_small_icon_white.svg);
}


.dark .box_btns .edit_small_icon:before {
	background-image: url(./assets/svg/edit_small_icon_white.svg);
}
.dark .react-tel-input .flag-dropdown.open .selected-flag {
    background: #222528;
}
.dark .react-tel-input .country-list
{
	background-color: #222429;
}
.dark .react-tel-input .country-list .search
{
	background-color: #222429;
}


.dark .css-2616qy-menu
{
	background-color: #232429  !important ;
	color:#fff !important ;
}
.dark .css-1pndypt-Input
{
	color:#fff;
}
.dark .css1n7v3ny-option 
{
	background-color:#333 !important;
}

.dark .planning-main-wrapper .box_btns ul li a
{
    border-color: #44494E;
}
.dark .dashboard-main-wrapper.box_btns ul li a {
    border-color: #44494E;
}

.dark .tabs_one ul li a.active {
    border: 1px solid #4FB155;
    border-bottom: 0;
    border-radius: 5px 5px 0px 0px;
    font-weight: 600;
	color: #4FB155;
}
.dark .tabs_one ul li a
{

    border-bottom: 1px solid #667085;
}
.dark .tabs_one ul li
{
	color:#667085;
}
.dark .landing .feature .box 
{
	background-image: none;
}
.dark .landing .left-side-bg
{
	background-image: url(./assets/images/left-side-landingdark.png);
	background-color:#1E2420;
}
.dark .landing .quotes:before
{
	background-image: url(./assets/images/quotes-before-dark.png);
}
.dark .landing .quotes:after
{
	background-image: url(./assets/images/quotes-after-dark.png);
}
.dark .landing .landing-hr-right
{
	background-image: url(./assets/images/landing_right_hr_dark.png);
}

.dark .landing .hr-ftf-bg {
	background-image: url(./assets/images/hr-ftf-bg_dark.png);
	
}

.dark .landing-hr .hr-ftf-bg .total_emp_icon:before {
	background-image: url(./assets/images/total-emp-icon_dark.png);
}
.dark .landing-hr .hr-ftf-bg .role_icon:before {
	background-image: url(./assets/images/role_icon_dark.png);
}
.dark .landing-hr .hr-ftf-bg .sales_icon:before {
	background-image: url(./assets/images/sales_icon_dark.png);
}
.dark .landing-hr .hr-ftf-bg .brand_icon:before {
	background-image: url(./assets/images/brand_icon_dark.png);
}
.dark .dashboard-main-wrapper .box_btns ul li a {
    font-size: 13px;
    border-color: #484848
}
.dark .dashboard-main-wrapper .box_btns ul li .outlined
{
	border: 1px solid #029046;
    color: #029046;
}
.dark .dashbord_box_shape
{
	background-image: url(../src/assets/images/dashboard_box_planning_bg_dark.png);
	background-position: bottom right;
}

.dark .p-paginator {
    background: #424242;
    color: #ececec;
    border: solid #2d2d2d;
}
.dark .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #33373a;
    border-color: #EEF2FF;
    color: #ffffff;
}

.dark .p-dropdown .p-dropdown-trigger {
    background: #424242;
    color: #6c757d;
}
.dark .p-dropdown .p-dropdown-trigger {
    background: #424242;
    background: #333231;
    color: #6c757d;
}
.dark .p-dropdown .p-dropdown-label
{
	background: #333231;
	
}
.dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item
{
	color: #fff;
}
.dark .reportshadow {
    border: 1px solid #1d2123;
}
.dark .notification .box
{
	background-color: #232528;
    border-color: #1c2223;
}
.dark .notification .notification-block
{
	background-color: #2A2E32;
}
.dark .notification .display .close_icon_notification:before
{
	background-image: url(./assets/svg/close_icon_notification_white.svg);
}
.dark .chat-wrapper .tabs_chat ul li a.active
{
	border-bottom-color: #029046;
    color: #4FB155;
}
.dark .purple .circle-text
{
	color:#101828;
}
.dark .chat-wrapper .tabs_chat ul li a {
    border-bottom: 2px solid #667085;
	color: #667085;
}
.dark .chat-wrapper .chatname
{
	color:#fff;
}
.dark .chat-wrapper .chat-list a .active {
    background: #2f9047;
}
.dark .chat-wrapper .active .time
{
	color:#fff;
}
.dark .chat-wrapper .active p
{
	color:#fff;
}
.dark .chat-wrapper .chat-list a .shadow-chatbox:hover
{
	background: rgba(84, 164, 83, 0.4);
}

.dark .chat-wrapper .chat-header
{
	background: rgba(255, 255, 255, 0.1)
}
.dark .chat-wrapper .chat-header h5
{
	color: white;
}
.dark .chat-wrapper .chat-header h6
{
	color: #959595;
}
.dark .chat-wrapper .msg-send-section input
{
	background-color: #171717;
	border: 1px solid #313131;
}
.dark .chat-wrapper .chat-body .receive-message {
    background-color: #44494e;
	color: #fff;
}
.dark .successfully_box
{
	border: 1px solid #4E5668;
	background: rgba(255, 255, 255, 0.1);
}

.pi-filter-icon 
{
	color: #fff;
}
 .p-column-filter-menu-button:hover {
    
    background: #2f9047 !important;
}
 .p-column-filter-menu-button.p-column-filter-menu-button-open, .dark  .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #2f9047 !important;
    color: #4fb156;
}
/*********** light***********/
.p-button.p-button-outlined {
    background-color: transparent !important;
    border-color: #4fb156 !important;
    color: #4fb156!important;
}
.p-button
{
	background-color: #4fb156 !important;
    border-color: #4fb156 !important;
    color: #fff!important;
}
/*********** TABLE CHECKBOX************/
.p-checkbox .p-checkbox-box
{
	border: 1px solid #C8CBD0 !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 4px !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon
{
	font-size: 10px !important;
    font-weight: 600;
}
/*********** TABLE CHECKBOX END************/
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight
{
	background: #b3ddc7 !important;
    border-color: #EEF2FF !important;
    color: #2f9047 !important;
}
.p-paginator .p-paginator-pages .p-paginator-page
{
	min-width: 2rem !important;
    height: 2rem !important;
}


/*********** light End***********/

.dark .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #fff;
    background: #4fb156;
}
.dark .p-checkbox .p-checkbox-box {
    border: 2px solid #171717;
    background: #33373a;
}
.dark .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover
{
	background: #4fb156;
}
.dark .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover
{	
	border-color: #4fb156;
    background: #4fb156;

}
.dark .p-column-filter-overlay 
{
	background-color: #2A2E32;
}
.dark .p-column-filter-overlay-menu .p-column-filter-operator
{
	background-color: #2A2E32;
	border-bottom: 1px solid #3d3d3d;
}
.dark .p-dropdown
{
	background: #2a2e32;
    border-color: #404143;
}
.dark .p-dropdown-panel {
    background: #33373a;
}
.dark .p-button.p-button-text
{
	color:#fff;
}
.dark .p-button.p-button-outlined
{
	background-color: #4A5056;
    border-color: #4A5056;
    color: #fff;
}
.dark .p-button {
    color: #ffffff;
    background: #029046;
    border: 1px solid #029046;
}
.dark .p-button.p-button-text:enabled:hover, .dark .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover
{
    background: rgb(79 177 86 / 50%);
    color: #ffffff;
}
.dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight 
{
	color: #ffffff;
    background: #2f9047;
}
/***************** end*******************/


.dark .page-title {
	color: #fff;
}
.dark .dashboard-main-wrapper .css-1s2u09g-control {
	background: #2A2E32;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border: none !important;
	border-radius: 8px;
	border: 1px solid #4E5668 !important;
}
.dark .dashboard-main-wrapper .css-14el2xx-placeholder,
.dark .dashboard-main-wrapper .placeholder-gray-500::placeholder,
.dark .dashboard-main-wrapper .css-6j8wv5-Input,
.dark .dashboard-main-wrapper .css-qc6sy-singleValue {
	color: #fff;
}
.dark .contract-grid-wrapper .placeholder-gray-500::placeholder{
	color: #8E8E8E;
}

.dark .iconbox {
	background: #333231;
	border-radius: 8px;
}
.dark .login-bg {
	background: #2A2E32;
}

.dark .css-1s2u09g-control:focus,
.dark .css-1pahdxg-control {
	background: #403E3C !important;
}
.dark .css-1s2u09g-control{
	background-color: #333231;
	border: 1px solid #333231 !important;
}
/* .dark .dashboard-main-wrapper input::placeholder,  */
.dark .dashboard-main-wrapper textarea::placeholder{
	color: #8E8E8E !important;
}
.dark .headar-wrapper .user_icon:before {
	background-image: url(../src/assets/svg/dark_header_user.svg);
}
.dark .headar-wrapper .lock_icon:before {
	background-image: url(../src/assets/svg/dark_lock_icon.svg);
}
.dark .headar-wrapper .header_swtich:before {
	background-image: url(../src/assets/svg/dark_header_swtich.svg);
}
.dark .headar-wrapper .logout_icon:before {
	background-image: url(../src/assets/svg/dark_logout_icon.svg);
}
.dark .previous,
.dark .next {
    border: none !important;
	background: #333231;
}
.dark .previous a,
.dark .next a{
	color: #F8F8F8 !important;
}
.dark .sc-cjibBx li a{
	color: #FFFFFF !important;
}

.dark .custom-views .fc-theme-standard th{
	border-right: 1px solid rgba(29, 28, 26, 1) !important;
	border-bottom: 1px solid rgba(29, 28, 26, 1) !important;
	background: linear-gradient(180deg, #1D1C1A 0%, #252423 100%);
	border-radius: 10px 10px 0px 0px;
	color: #FFFFFF !important;
}
.dark .fc-scrollgrid-sync-inner{
	padding: 10px 0;
	font-weight: 500;
}
.dark .fc-media-screen{
	background: #2D2B29;
	border: 1px solid #403E3C;
	border-radius: 8px;
	padding: 10px 20px 20px;
}

.dark .custom-views .fc-theme-standard .fc-scrollgrid{
	border: 1px solid rgba(29, 28, 26, 1) !important;
	border-radius: 15px !important;
}
.dark .custom-views .fc-theme-standard td{
	border: none !important;
}
.dark .custom-views .fc .fc-daygrid-day-frame{
	border-left: 2px solid rgba(29, 28, 26, 1);
	border-top: 2px solid rgba(29, 28, 26, 1);
}
.dark .custom-views .fc-daygrid-day-number,
.dark .custom-views .fc-toolbar-title{
	color: #F8F8F8 !important;
}
.dark .custom-views .fc-button-group .fc-button-primary,
.dark .custom-views .fc .fc-button-primary:disabled{
	background: #403E3C;
	border-radius: 6px;
	border: none !important;
}
.dark .custom-views .fc-toolbar-chunk{
	background: transparent !important;
}

.dark .custom-views .fDNIAE,
.dark .dashboard-main-wrapper .fDNIAE{
	background: #333231;
 }

 .dark .rdt_TableRow:nth-child(odd) {
	background-color: #2D2B29 !important;
	color: #fff !important;
}
 .dark .rdt_TableRow:nth-child(even) {
	background-color: #333231 !important;
	color: #fff !important;
}
.dark .rdt_TableHeadRow{
	border-bottom: none !important;
}
.dark .custom-views .iSAVrt{
	min-height: 45px !important;
}
.dark .bg-body-blue {
	background: linear-gradient(180deg, #1D1C1A 0%, #252423 100%);
}
.dark .css-qc6sy-singleValue {
	color: #8E8E8E  !important;
}
.dark .bg-half-blue {   
	background: #333231 !important;
}
.dark .arrow_right:before {
	position: absolute;
   content: "";
   background-image: url(../src/assets/svg/dark_arrow_right.svg);
   background-repeat: no-repeat;
   height: 20px !important;
   width: 20px !important;
   top: 4px;
   right: 0px;
   
}
.dark .view_icon:before {
	background-image: url(../src/assets/svg/dark_view.svg);
	top: -10px !important;
}
.view_icon:before {
	background-image: url(../src/assets/svg/eye_icon.svg);
	top: -10px !important;
}
.check_circle_green_icon:before {
	background-image: url(../src/assets/svg/check_circle_green_icon.svg);
	top: -10px !important;
}
.check_circle_grey_icon:before {
	background-image: url(../src/assets/svg/submit.svg);
	top: -10px !important;
}


.dark .rdt_Pagination{
	background: #424242;
    border: 1px solid #2d2d2d;
	color:#FFF;
}

.dark .rdt_Pagination button{
	color:#FFF;
	fill: #FFF;
}

.dark .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
{
	background-color:#4fb155;
	color:#FFF;
}

.dark .p-datatable .p-sortable-column .p-sortable-column-icon
{
	color: #FFF;
}
.dark .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon
{
	color: #FFF;
}

.dark .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover
{
	background-color:#4fb155;
	color:#FFF;
}

.dark .p-datatable .p-datatable-thead > tr > th
{
	border-color:#4fb155;
}

.dark .p-datatable .p-sortable-column:focus
{
	box-shadow: none;
}


.dark .custom-views .lmSJmf,
.dark .custom-views .jaZsat,
.dark .custom-views .dLyRAV,
.dark .lmSJmf,
.dark .dLyRAV,
.dark .sc-cwSeag{
	color: #fff !important;
}
.dark .fyrdjl:disabled,
.gMliOo svg{
	fill: #fff !important;
}

.ReactModal__Overlay
{
	background-color: rgb(25 25 25 / 75%) !important;
}
.dark .delete_icon:before {
	background-image: url(../src/assets/svg/dark_delete_icon.svg);
	top: -10px !important;
} 
.dark .edit_icon:before {
	background-image: url(../src/assets/svg/dark_Edit_Square.svg);
	top: -10px !important;
}
.dark .change_password:before {
	background-image: url(../src/assets/svg/dark_key_icon.svg.svg);
	top: -10px !important;
} 

.dark .css-1insrsq-control{
	background: #333231;
	border: 1px solid #333231;
 }
 .dark .css-109onse-indicatorSeparator{
	 background-color: transparent !important;
 }
 .dark .kNUuTe{
	 background-color: transparent !important;
 }

 .dark .ReactModal__Content{
	background-color: #2D2B29 !important;border: 1px solid #2D2B29 !important;
 }

 .dark .customize .box{
	background: #2D2B29;
	border: 1px solid #8E8E8E;
	border-radius: 8px;
 }
 .dark .customize-popup .ReactModal__Content{
	background: #333231 !important;
	box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important;
	border-radius: 12px !important;
 }
 .disabledCursor { 
	cursor: default;
}

.dark .alertify .ajs-header
{
	background-color:#4fb155!important;
	color: #FFF;
}

.dark .alertify .ajs-dialog
{
	background-color: #333231;
	color: #FFF;
}

.dark .alertify .ajs-footer
{
	background-color: #333231;
	color: #FFF;
}

.dark .alertify .ajs-ok
{
	background-color:#4fb155!important;
	color: #FFF;
	border-color: #333231;
}


.dark .alertify .ajs-cancel
{
	background-color:#4a5056!important;
	color: #FFF;
	border-color: #4a5056;
}

.alertify .ajs-button
{
	border-radius: 0.375rem;
	padding-left: 1.25rem;
    padding-right: 1.25rem;
	padding-top: 0.5rem;
    padding-bottom: 0.5rem;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5rem;
}
@media only screen and (max-width: 1536px) and (min-width: 1920px) 
{
	.chat-list
	{
		height: 40rem !important ;
	
	}
}
.chat_button:before {
	background-image: url(../src/assets/svg/chat.svg);
	top: -10px !important;
	left: 9px !important
}

.dark .chat_button:before {
	background-image: url(../src/assets/svg/chat_dark.svg);
	top: -10px !important;
	left: 9px !important
}


@-moz-document url-prefix() { 
	.dashboard-main-wrapper .search_icon::before {
		top: 6px !important;
		right: 2px;
	}
	.target-main-wrapper .dashbord_box_shape  .s_icon::before
	{
		top: 19px;
	}
	.target-main-wrapper .dashbord_box_shape .t_icon::before
	{
		top: 19px;
	}
	.login form .icon
	{
		left: 200px;
	}
	.target-main-wrapper .activegreen .s_icon::before
	{
		top: 19px;
	}
	.target-main-wrapper .s_icon::before
	{
		top: 19px;
	}
	.p-datatable-wrapper .delete_icon::before
	{
		top: 3px !important;
		left: -7px !important;
	}
	.view_icon:before
	{
		top: 0 !important;
	}
	.edit_icon:before {
		
		top: 0px !important;
	}
	.userFile_icon:before {
		top: 0px !important;
	}
	.delete_icon::before
	{
		top:0 !important
	}
  }





.p-inputgroup-addon
{
	padding: 5px!important;
}

.alertify .ajs-footer
{
	padding: 15px !important;
}
.alertify .ajs-button {
    border-radius: 5px !important;
	border-radius: 7px !important;
    padding: 6px 10px !important; 
}
.ajs-ok {
    border: solid 1px #ccc;
	background-color: #029046 !important;
    color: #FFF;
}
.ajs-header {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    font-size: 18px;
  	border-top-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
}
.alertify .ajs-footer
{
	border-bottom-right-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}
.alertify .ajs-dialog
{
	border-radius: 5px !important;
}
.my-react-select__control--is-disabled
{
	background-color: #f9f9f9 !important;
}

.nodata 
{
	text-align: center;
}
.nodata h1
{
	font-size: 23px;
	padding-top: 25px;

}
.dark .nodata h1 
{
	color: #b0b0b0;
}
.nodata .icon 
{
	display: inline-block;
	position: relative;
	height: 220px !important;
	width: 150px !important;
}
.nodata .nodatafound:before {
	position: absolute;
   content: "";
   background-image: url(../src/assets/images/nodatafound.png);
   background-repeat: no-repeat;
   height: 220px !important;
   width: 150px !important;
   top: 20px;
   right: 0px;
}
.box .nodata .icon
{
	display: inline-block;
	position: relative;
	height: 92px !important;
	width: 92px !important;
}
.nodata .bell_icon:before {
	position: absolute;
   content: "";
   background-image: url(../src/assets/svg/bell_icon.svg);
   background-repeat: no-repeat;
   height: 92px !important;
   width: 92px !important;
   top: 0px;
   right: 0px;
}
.notification-details .icon
{
	display: inline-block;
	position: relative;
	height: 50px !important;
	width: 50px !important;
}
.notification-details .bell_icon:before {
	position: absolute;
   content: "";
   background-image: url(../src/assets/svg/bell_small.svg);
   background-repeat: no-repeat;
   height: 50px !important;
   width: 50px !important;
   top: 0px;
   right: 0px;
}


.font-semibold
{
	font-weight: 500;
}
.p-datatable .p-sortable-column .p-sortable-column-icon
{
	color: #343a40 !important;
}
.pi-filter-icon {
    color: #a2a2a2;
}
.dark .p-datatable .p-sortable-column .p-sortable-column-icon
{
	color: #a2a2a2 !important;
}


/***************** settings icon ****************/



.setting-wrapper .icon {
	position: relative;
	width: 64px;
	height: 64px;


}

.setting-wrapper .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 64px;
	height: 64px;
	background-repeat: no-repeat;
}

.setting-wrapper .emp_icon:before {
	background-image: url(./assets/images/master_icons/Employee1x.png);
	
}
.dark .setting-wrapper .emp_icon:before {
	background-image: url(./assets/images/master_icons/Employees1x_dark.png);
	
}

.setting-wrapper .roles_icon:before {
	background-image: url(./assets/images/master_icons/Role_1x.png);
	
}
.dark .setting-wrapper .roles_icon:before {
	background-image: url(./assets/images/master_icons/Role_1x_dark.png);
	
}

.setting-wrapper .parameter_icon:before {
	background-image: url(./assets/images/master_icons/Parameters_1x.png);
	
}
.dark .setting-wrapper .parameter_icon:before {
	background-image: url(./assets/images/master_icons/Parameter_1x_dark.png);
	
}

.setting-wrapper .fy_icon:before {
	background-image: url(./assets/images/master_icons/Financial_Year_1x.png);
	
}
.dark .setting-wrapper .fy_icon:before {
	background-image: url(./assets/images/master_icons/Financial_Year_1x_dark.png);
	
}

.setting-wrapper .fy_icon:before {
	background-image: url(./assets/images/master_icons/Financial_Year_1x.png);
	
}
.dark .setting-wrapper .fy_icon:before {
	background-image: url(./assets/images/master_icons/Financial_Year_1x_dark.png);
	
}

.setting-wrapper .sbu_icon:before {
	background-image: url(./assets/images/Dimensions/1x/SBU_1x.png);
	
}
.dark .setting-wrapper .sbu_icon:before {
	background-image: url(./assets/images/Dimensions/1x/SBU_1x_dark.png);
	
}

.setting-wrapper .brand_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Brand_1x.png);
	
}
.dark .setting-wrapper .brand_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Brand_1x_dark.png);
	
}

.setting-wrapper .pg_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Product_Group_1x.png);
	
}
.dark .setting-wrapper .pg_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Product_Groups_1x_dark.png);
	
}

.setting-wrapper .pc_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Profit_center_1x.png);
	
}
.dark .setting-wrapper .pc_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Profit_center_1x_dark.png);
	
}

.setting-wrapper .bu_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Business_Units_1x.png);
	
}
.dark .setting-wrapper .bu_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Business_Units_1x_dark.png);
	
}

.setting-wrapper .region_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Regions_1x.png);
	
}
.dark .setting-wrapper .region_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Regions_1x_dark.png);
	
}

.setting-wrapper .country_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Countries_1x.png);
	
}
.dark .setting-wrapper .country_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Countries_1x_dark.png);
	
}

.setting-wrapper .company_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Company_1x.png);
	
}
.dark .setting-wrapper .company_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Company_1x_dark.png);
}

.setting-wrapper .custSegment_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Customer_Segment_1x.png);
	
}
.dark .setting-wrapper .custSegment_icon:before {
	background-image: url(./assets/images/Dimensions/1x/customer_segment_1x_dark.png);
	
}

.setting-wrapper .custGroup_icon:before {
	background-image: url(./assets/images/Dimensions/1x/customer_Group_1x.png);
}
.dark .setting-wrapper .custGroup_icon:before {
	background-image: url(./assets/images/Dimensions/1x/customer_Group_1x_dark.png);
}

.setting-wrapper .customer_icon:before {
	background-image: url(./assets/images/Dimensions/1x/customer_1x.png);
}
.dark .setting-wrapper .customer_icon:before {
	background-image: url(./assets/images/Dimensions/1x/customer_1x_dark.png);
}

.setting-wrapper .verticals_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Vertical_1x.png);
}
.dark .setting-wrapper .verticals_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Verticals_1x_dark.png);
}

.setting-wrapper .division_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Division_1x.png);
}
.dark .setting-wrapper .division_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Division_1x_dark.png);
}

.setting-wrapper .pSL_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Plant_storage_location_1x.png);
}
.dark .setting-wrapper .pSL_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Plant_storage_location_1x_dark.png);
}

.setting-wrapper .materials_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Plant_storage_location_1x.png);
}
.dark .setting-wrapper .materials_icon:before {
	background-image: url(./assets/images/Dimensions/1x/Plant_storage_location_1x_dark.png);
}

.drilldown .echarts-for-react  
{
	height: 500px !important;
}
.width-20 {
	width: 20%;
}
.smoothlinechart .echarts-for-react 
{
	height:80px !important;

}
.width-60 {
	width: 60%;
}
.width-40 {
	width: 40%;
}
.width-30 {
	width: 30%;
}

.strike{
	color:red;text-decoration:line-through;
}
.record-validate{
	padding: 5px 19px 0px 18px;
  }




