/*************** dashboard ProConnect Summary*****************/
.dashboardproconnect .tabs_two ul li a {
    display: block;
    padding: 5px 15px 3px;
    border-bottom: 1.5px solid #C8CBD0;
    color: #667085;
}
.dashboardproconnect .tabs_two ul li a.active {
    font-weight: 500;
    border: 1px solid #101828;
    border-top-left-radius: 7px;
    border-bottom-color: transparent;
    border-top-right-radius: 8px;
    color: #101828;
}
.dark .dashboardproconnect .tabs_two ul li a.active
{
    color: #fff;
    border: 1px solid #fff;
}
.boxshadow
{
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    
}

.dashboardproconnect .overallstatus .icon  {
	position: relative;
	width: 48px;
	height: 48px;
}

.dashboardproconnect .overallstatus .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 48px;
	height: 48px;
	background-repeat: no-repeat;
}

.dashboardproconnect .overallstatus .monitor_icon:before {
	background-image: url(./assets/svg/connectpro/monitor_icon.svg);
}
.dashboardproconnect .overallstatus .inquiresopen_icon:before {
	background-image: url(./assets/svg/connectpro/inquiresopen_icon.svg);
}
.dashboardproconnect .overallstatus .inquiresaccpt_icon:before {
	background-image: url(./assets/svg/connectpro/inquiresaccpt_icon.svg);
}
.dashboardproconnect .overallstatus .inquiesdecline_icon:before {
	background-image: url(./assets/svg/connectpro/inquiesdecline_icon.svg);
}
.dashboardproconnect .overallstatus .inquiesclose_icon:before {
	background-image: url(./assets/svg/connectpro/inquiesclose_icon.svg);
}
.dashboardproconnect .overallstatus .inquieslifeline_icon:before {
	background-image: url(./assets/svg/connectpro/inquieslifeline_icon.svg);
}

/*************** dashboard ProConnect Summary end*****************/


/********************* Inquies ID ****************/
.p-component 
{
    font-family: 'Inter', sans-serif !important;
}
.inquiesid .p-tabview .p-tabview-nav{
    background: transparent;
    border-color: transparent;
}
.inquiesid .p-tabview .p-tabview-nav
{
  
}
.inquiesid .p-tabview .p-tabview-nav li .p-tabview-nav-link
{
    background: transparent;
    padding: 13px 25px;
    color: #667085;
    font-weight: 400;
}
.inquiesid .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
{
    background: transparent;
    color: #101828;
    font-weight: 600;
}
.inquiesid .p-tabview .p-tabview-panels
{
    background: transparent;
    padding: 10px 10px;
}
.inquiesid .p-tabview .p-tabview-nav .p-tabview-ink-bar
{
    background-color: #029046;
}
.inquiesid .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
{
    box-shadow: inset 0 0 0 0.2rem transparent;
}


.inquies .icon {
	position: relative;
	width: 15px;
	height: 15px;

}

.inquies .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
	width: 15px;
	height: 15px;
	background-repeat: no-repeat;
}
.inquies .arrow_left_double:before {
	background-image: url(./assets/svg/arrow_left_double.svg);
	width: 20px;
	height: 20px;
	margin-right: 24px
}
/********************* Inquies ID end ****************/
/*************** Vartical Tab **********************/
.list-tab ul li 
{
    background: #FFFFFF;
    border-bottom: 4px solid #A7D8AA;
    border-radius: 8px;
    padding: 32px 20px;
    font-size: 17px;
    font-weight: 500;
}
.dark .list-tab ul li
{
    background: #2a2e32;
    color: #fff;
}
.list-tab ul .active 
{
    background: #4FB155;
    border-bottom: 4px solid #377B3B;
    color: #fff;
}

.list-tab  .icon  {
	position: relative;
	width: 35px;
	height: 35px;
    margin-right: 60px;
}

.list-tab  .icon:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: inline-block;
    width: 40px;
    height: 38px;
	background-repeat: no-repeat;
}

.list-tab .requestorinfo_icon:before {
	background-image: url(./assets/svg/connectpro/requestorinfo_icon.svg);
}
.list-tab .basicinfo_icon:before {
	background-image: url(./assets/svg/connectpro/basicinfo_icon.svg);
}
.list-tab .moreinfo_icon:before {
	background-image: url(./assets/svg/connectpro/moreinfo_icon.svg);
}
.list-tab .attchement_icon:before {
	background-image: url(./assets/svg/connectpro/attchement_icon.svg);
}
.list-tab ul .active .activeicon:before {
	background-image: url(./assets/svg/connectpro/active_icon.svg);
}
.target-main-wrapper .viewfile .icon
{
    width: 40px;
    height: 40px;
}
.viewfile .file_icon:before {
	background-image: url(./assets/svg/connectpro/file-text.svg);
    width: 40px;
    height: 40px;
}
.viewfile .image_icon:before {
	background-image: url(./assets/svg/connectpro/image_icon.svg);
    width: 40px;
    height: 40px;
}
.viewfile .close_icon_green:before {
	background-image: url(./assets/svg/connectpro/close_icon_green.svg);
    width: 40px;
    height: 40px;
}
.viewfile .delete_icon_red:before {
	background-image: url(./assets/svg/connectpro/delete_icon_red.svg);
    width: 40px;
    height: 40px;
}
/*************** Vartical Tab end **********************/

/**************** inquiesbids ***************************/

.inquiesbids .right-side .icon
{
    height: 20px;
    width: 20px;   
}
.inquiesbids .info_icon:before {
	background-image: url(./assets/svg/connectpro/info_icon.svg);
    height: 20px;
    width: 20px;
}
.inquiesbids .bids_feture  .invited-bg
{
    background-image: url(./assets/svg/connectpro/invited_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}
.inquiesbids .bids_feture .responded-bg
{
    background-image: url(./assets/svg/connectpro/responded_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}
.inquiesbids .bids_feture .unresponsive-bg
{
    background-image: url(./assets/svg/connectpro/unresponded_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}
.inquiesbids .bids_feture .declined-bg
{
    background-image: url(./assets/svg/connectpro/declined_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}
.inquiesbids .bids_feture .confirmed-bg
{
    background-image: url(./assets/svg/connectpro/confirmed_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}
.inquiesbids .bids_feture .status-bg
{
    background-image: url(./assets/svg/connectpro/status_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}


/**************** inquiesbids ***************************/
/*************** Vartical Tab end **********************/

/* ######################## popup ############################## */

.selectedBar{
    width:13em;
    border-radius:20px;
    padding: 5px;
}
.displaynone {
    position: relative;
}

.displaynone  input[type='file'] {
    opacity: 0;
    /* z-index: 100; */
    position: absolute;
    /* position: relative; */
    
  
  }
  .displaynone .img{
    z-index: 1000;
    /* position: absolute; */
    position: relative;
    
  }
